(function() {
"use strict"
// =========================================================

window.winnerPayBundleSrc = function() {
	const host = window.location.host
	const bundleSrc = (
        host.startsWith('localhost:8000') ? 'http://' + host :
        'https://' + (
            !( host.startsWith('alpha') || host.startsWith('localhost') ) ? '' : 'alpha0.'
        ) + 'baza-winner.ru/pay'
    ) + '/bundle.js'
    return bundleSrc
}

window.winnerPayBundleId = function() {
    return 'winnerPay'
}

window.winnerPay = function(value) {
    const bundleSrc = winnerPayBundleSrc()
	const bundleId = winnerPayBundleId()
	const asyncClosure = () => 
		new Promise((resolve, reject) => {
			const ret = window._winnerPay(value)
			if (ret.error) {
				reject(ret.error)
			} else {
				resolve(ret)
			}
		})
	const alwaysReload = true
	return window.lazyLoadAsyncRun({bundleId, bundleSrc, asyncClosure, alwaysReload})
}

// =========================================================
})()
